import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useFormContext } from 'react-form';
import { CircularProgress, makeStyles } from '@material-ui/core';
import { Button, type ButtonProps as MuiButtonProps } from '@popmenu/admin-ui';

import { useFeatureFlags } from '~/utils/featureFlagsContext';
import { executeWithProgressBar } from '~/utils/postponed';
import { classNames } from '../../../utils/withStyles';
import formStyles from '../../../assets/jss/shared/formStyles';
import { whenNoDebouncingInTextFields } from './withTextFieldDebouncer';

import Grid from '../../../shared/Grid';

export type SubmitGroupProps = {
  block?: boolean,
  ButtonProps?: Partial<MuiButtonProps & { component?: React.ElementType }>,
  CancelButtonProps?: Partial<React.ComponentProps<typeof Button>>,
  children?: React.ReactNode,
  className?: string,
  'data-cy'?: string,
  'data-tour-id'?: string,
  disabled?: boolean,
  id?: string,
  inline?: boolean,
  justify?: 'center' | 'flex-end' | 'flex-start',
  manualSubmit?:boolean,
  loading?: boolean,
  onCancel?: React.MouseEventHandler<HTMLButtonElement>,
  onClick?: React.MouseEventHandler<HTMLButtonElement>,
  size?: 'small' | 'medium' | 'large' | 'xl' | undefined,
  submitGroupButtonContainerStyle?: string,
  title?: React.ReactNode | string,
  variant?: 'outlined' | 'contained' | 'text',
};

const useStyles = makeStyles(formStyles);

const resolveVariant = (variant: SubmitGroupProps['variant']) => {
  switch (variant) {
    case 'outlined':
      return 'secondary';
    case 'contained':
      return 'primary';
    case 'text':
      return 'text';
    default:
      return 'primary';
  }
};

const resolveSize = (size: SubmitGroupProps['size']) => {
  if (size === undefined) return undefined;
  switch (size) {
    case 'small':
      return 'small';
    case 'medium':
      return 'medium';
    case 'large':
      return 'large';
    case 'xl':
      return 'large';
    default:
      return undefined;
  }
};

const SubmitGroup = ({
  ButtonProps = {},
  block = false,
  loading: loadingProp = false,
  CancelButtonProps = {},
  children = undefined,
  className = undefined,
  'data-cy': dataCy = 'submit',
  'data-tour-id': dataTourId,
  disabled = false,
  id = undefined,
  inline = false,
  justify = 'flex-end',
  manualSubmit = false,
  onCancel = undefined,
  onClick = undefined,
  size = undefined,
  submitGroupButtonContainerStyle = undefined,
  title = 'Save',
  variant = 'contained',
}: SubmitGroupProps) => {
  // Loading state from form metadata
  const { meta: { isValid, isSubmitting } } = useFormContext();
  const loading = loadingProp || isSubmitting;
  const classes = useStyles();
  const [textFieldDebouncing, setTextFieldDebouncing] = useState(false);

  let progressBarEnabled = false;
  try {
    // useFeatureFlags throws when no restaurant is provided.
    // However initial login page does not have a restaurant but it has SubmitGroup.
    // So we need to catch the error and do nothing.
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { isFeatureActive } = useFeatureFlags();
    progressBarEnabled = isFeatureActive('progress_bar');
  } catch {
    // Do nothing
  }

  const type = ButtonProps.type || (manualSubmit && typeof onClick === 'function' ? 'button' : 'submit');

  const debouncedOnClick = React.useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    if (ButtonProps?.component) {
      if (typeof onClick === 'function') {
        onClick(e);
      }
      return;
    }

    e.preventDefault(); // prevent triggering form submission
    const target = e.target as HTMLElement;
    if (!target) {
      return;
    }

    setTextFieldDebouncing(true);
    executeWithProgressBar(() => {
      void whenNoDebouncingInTextFields().then(() => {
        if (typeof onClick === 'function') {
          onClick(e);
        }

        if (type === 'submit') {
          target.closest('form')?.requestSubmit();
        }

        // React form is lazy in setting |loading| prop in time.
        // This makes it possible to submit the same form multiple times when clicking the same button fast.
        setTimeout(() => {
          setTextFieldDebouncing(false);
        }, 400);
      });
    });
  }, [ButtonProps?.component, onClick, type]);

  // Submit button
  const button = (
    <Button
      fullWidth={block}
      className={classNames(classes.submitButton, className)}
      data-cy={dataCy}
      data-tour-id={dataTourId}
      data-testid={'submit-group-button'}
      disabled={disabled || loading || !isValid || textFieldDebouncing}
      id={id}
      onClick={progressBarEnabled ? debouncedOnClick : onClick}
      size={resolveSize(size)}
      type={type}
      variant={resolveVariant(variant)}
      {...ButtonProps}
    >
      {loading && (<CircularProgress className={classes.submitButtonSpinner} size={16} />)} {title}
    </Button>
  );
  if (inline) {
    return button;
  }

  // Cancel button
  let cancelButton = null;
  if (typeof onCancel === 'function') {
    cancelButton = (
      <Button
        className={classes.cancelButton}
        data-cy="cancel"
        data-testid={'cancel-button'}
        disabled={disabled || loading}
        onClick={onCancel}
        size={resolveSize(size)}
        type="button"
        variant="text"
        {...CancelButtonProps}
      >
        <FormattedMessage id="forms.cancel" defaultMessage="Cancel" />
      </Button>
    );
  }
  return (
    <Grid
      className={classNames(className, classes.submitGroup)}
      container
      inline={!block}
      justify={justify}
    >
      {cancelButton && (
        <Grid item>
          {cancelButton}
        </Grid>
      )}
      <Grid item xs={block ? 12 : undefined} className={classNames([size === 'xl' ? classes.extraLarge : null, submitGroupButtonContainerStyle])}>
        {button}
      </Grid>
      {children && (
        <Grid item>
          {children}
        </Grid>
      )}
    </Grid>
  );
};

export default SubmitGroup;
