import React from 'react';
import PropTypes from 'prop-types';
import { compose, mapProps } from '@shakacode/recompose';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { addMinutes, isBefore, parseISO } from 'date-fns';
import { X as XIcon } from '@popmenu/web-icons';
import { Button } from '@popmenu/admin-ui';
import { Icon, Typography } from '@popmenu/common-ui';
import { IconButton } from '@material-ui/core';

import { withPopmenuConfig } from '../../../utils/withPopmenuConfig';
import { withRestaurant } from '../../../utils/withRestaurant';
import { withTheme } from '../../../utils/withTheme';
import { classNames, withStyles } from '../../../utils/withStyles';
import menuItemDetailsInfoStyles from '../MenuItemDetailsInfo/styles';
import menuItemModernDetailsInfoStyles from './styles';
import { formatCurrency } from '../../../utils/currency';

import MenuItemModernDetailsInfoSkeleton from '../MenuItemModernDetailsInfoSkeleton/MenuItemModernDetailsInfoSkeleton';
import MenuItemModernDetailsTabs from '../MenuItemModernDetailsTabs';
import MenuItemDetailsGallery from '../MenuItemDetailsGallery';
import { AH, AHLevelProvider } from '../../shared/AccessibleHeading';

import { isOutOfStock } from '../MenuHelpers';
import ModernEngagementIcons from '../../shared/ModernEngagementIcons';

class MenuItemModernDetailsInfo extends React.PureComponent {
  render() {
    const { bLayout, classes, closeMenuItemModal, isModal, loading, menuItem, menuItemCartLocationId, modernLayout, openModalInCart, openModernMenuCartModal,
      openReviewModal, restaurant, showAddToCartButton, theme, menuEnabled, isScheduled, scheduledAt, onSubmitComplete, menuOrderingUrl } = this.props;

    if (loading || !menuItem) {
      return (
        <MenuItemModernDetailsInfoSkeleton />
      );
    }
    const cartIsNewLocation = menuItemCartLocationId && menuItemCartLocationId !== menuItem.section.menu.locationId;

    const outOfStock = isOutOfStock({
      outOfStock: menuItem?.outOfStock,
      outOfStockUntil: menuItem?.outOfStockUntil,
      scheduledAt,
    });
    const invalidCateringLeadTime = menuItem.section.menu.isCateringMenu &&
      menuItem.section.menu.cateringLeadTimeMinutes > 0 &&
      isScheduled &&
      isBefore(parseISO(scheduledAt), addMinutes(new Date(), menuItem.section.menu.cateringLeadTimeMinutes));

    let orderable = menuItem.isOrderingEnabled && !outOfStock && menuEnabled && !invalidCateringLeadTime && !cartIsNewLocation;
    // When in a menu section (not online ordering section), a menuOrderingUrl must be present for ordering to be possible.
    // The server side calculations for menuOrderingUrl include a check if the menu is currently available for ordering.
    // If the menu is not currently available for ordering, menuOrderingUrl will be null
    if (!showAddToCartButton && (!menuItem.section.menu.menuOrderingUrl && !menuOrderingUrl)) {
      orderable = false;
    }

    const showOrderLink = (
      !this.props.menuEmbed
    );

    const closeIconClasses = classNames(
      !bLayout ? classes.modernCloseIconContainer : classes.modernCloseIconContainerLayoutB,
      !bLayout && menuItem.photos.length === 0 ? classes.modernCloseIconContainerNoPhoto : null,
      bLayout && menuItem.photos.length === 0 ? classes.modernCloseIconContainerNoPhotoLayoutB : null,
    );

    const closeButtonClasses = classNames(
      !bLayout ? classes.modernCloseButtonContainer : classes.modernCloseButtonContainerLayoutB,
      !bLayout && menuItem.photos.length === 0 ? classes.modernCloseButtonContainerNoPhoto : null,
      bLayout && menuItem.photos.length === 0 ? classes.modernCloseButtonContainerNoPhotoLayoutB : null,
    );

    return (
      <div
        className={classNames(
          classes.infoContainer,
          closeMenuItemModal ? classes.infoContainerModal : null,
          menuItem.photos.length !== 0 ? classes.modernDishDetailsResetPadding : null,
          menuItem.photos.length !== 0 && bLayout ? classes.modernDishDetailsResetPaddingLayoutB : null,
          modernLayout && classNames(classes.modernDishDetailsContainer, 'pm-modern-dish-details'),
          !bLayout ? 'layoutA' : classes.modernAdjustPaddingLayoutB,
        )}
        id="pm-item-scroll-container"
      >
        <section>
          {
            !bLayout && (
              <MenuItemDetailsGallery
                closeMenuItemModal={null}
                menuItem={menuItem}
                modernLayout={modernLayout}
              />
            )
          }
          <div className={classNames([classes.infoTop, classes.modernInfoTop, 'modern-info-top'])}>
            <div
              className={classNames(isModal ? closeIconClasses : closeButtonClasses)}
            >
              {isModal && (
                <IconButton onClick={closeMenuItemModal} className={classes.modernCloseIcon} aria-label="Back To Menu">
                  <Icon size="large" icon={XIcon} />
                </IconButton>
              )}
              {!isModal && (
                <Button
                  aria-label="Back To Menu"
                  className={classes.modernCloseButton}
                  onClick={closeMenuItemModal}
                  variant="secondary"
                >
                  <FormattedMessage id="consumer.ordering.back_to_menu" defaultMessage="Back To Menu" />
                </Button>
              )}
            </div>
            <div className={classes.modernIcons}>
              <ModernEngagementIcons menuItem={menuItem} restaurant={restaurant} />
            </div>
          </div>
          <Typography className={classes.modernLocationName}>
            {menuItem.section.menu.location.name}
          </Typography>
          <AH typography className={classNames(classes.modernDishName, 'pm-menu-item-details-name')} variant="h1">
            {menuItem.name}
          </AH>
          {theme.showPrice && Boolean(menuItem.priceCustomText || menuItem.price) && (
            <Typography className={classNames(classes.dishPrice, 'pm-menu-item-details-price')}>
              {menuItem.priceCustomText || formatCurrency(menuItem.price, menuItem.currency, { showSymbol: theme.showCurrencySymbol })}
            </Typography>
          )}
          <AHLevelProvider>
            <MenuItemModernDetailsTabs
              closeMenuItemModal={closeMenuItemModal}
              hasPhoto={menuItem.photos.length > 0}
              includeItemLink={!closeMenuItemModal}
              isLayoutB={bLayout}
              menuItem={menuItem}
              modernLayout={modernLayout}
              openReviewModal={openReviewModal}
              theme={theme}
              openModalInCart={openModalInCart}
              openModernMenuCartModal={openModernMenuCartModal}
              orderable={orderable}
              selectedMenuItem={this.props.selectedMenuItem}
              showAddToCartButton={showAddToCartButton}
              showOrderLink={showOrderLink}
              onSubmitComplete={onSubmitComplete}
            />
          </AHLevelProvider>
        </section>
      </div>
    );
  }
}

MenuItemModernDetailsInfo.defaultProps = {
  bLayout: false,
  closeMenuItemModal: null,
  isScheduled: false,
  loading: true,
  menuEnabled: true,
  menuItem: null,
  menuOrderingUrl: null,
  modernLayout: false,
  scheduledAt: null,
};

MenuItemModernDetailsInfo.propTypes = {
  bLayout: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  closeMenuItemModal: PropTypes.func,
  isModal: PropTypes.bool.isRequired,
  isScheduled: PropTypes.bool,
  loading: PropTypes.bool,
  menuEmbed: PropTypes.bool.isRequired,
  menuEnabled: PropTypes.bool,
  menuItem: PropTypes.shape({
    dishId: PropTypes.number,
    id: PropTypes.number,
    name: PropTypes.string,
    reviews: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
    })),
  }),
  menuOrderingUrl: PropTypes.string,
  modernLayout: PropTypes.bool,
  openModalInCart: PropTypes.func.isRequired,
  openModernMenuCartModal: PropTypes.func.isRequired,
  openReviewModal: PropTypes.func.isRequired,
  scheduledAt: PropTypes.string,
  showAddToCartButton: PropTypes.bool.isRequired,
  theme: PropTypes.shape({
    showCurrencySymbol: PropTypes.bool,
    showPrice: PropTypes.bool,
    useLegacyDishLayout: PropTypes.bool,
  }).isRequired,
};

export default compose(
  withRestaurant,
  withTheme,
  withStyles(theme => ({ ...menuItemDetailsInfoStyles(theme), ...menuItemModernDetailsInfoStyles(theme) })),
  withPopmenuConfig,
  connect(state => ({
    isScheduled: state.menuItemCart.menuItemCartIsScheduled,
    menuItemCartLocationId: state.menuItemCart.menuItemCartLocationId,
    scheduledAt: state.menuItemCart.menuItemCartScheduledAt,
  })),
  mapProps(({ popmenuConfig, ...props }) => ({
    ...props,
    menuEmbed: !!popmenuConfig.widget,
  })),
)(MenuItemModernDetailsInfo);
