import { Button, Icon, Tooltip } from '@popmenu/common-ui';
import { FormattedMessage } from 'react-intl';
import React, { memo } from 'react';
import { useReactiveVar } from '~/lazy_apollo/client';
import { useMutation } from '~/lazy_apollo/client';
import { executeWithProgressBar } from '~/utils/postponed';
import { compose, mapProps } from '@shakacode/recompose';
import { classNames, makeStyles } from '../../../utils/withStyles';
import { POP_ICONS, type PopType } from '../../../utils/pops';
import { buildPopCounts, onPopButtonClick, setUpPopType } from './PopBubble';
import { userPopsVar } from './userPopsVar';
import { withCurrentSession, type WithCurrentSessionProps } from '../../../shared/CurrentSessionProvider';
import { useIntl } from '../../../utils/withIntl';
import styles from './styles';
import { connector, type InnerSharedPopBubbleProps, type SharedPopBubbleProps } from './shared';
import { withRestaurant, type WithRestaurantProps } from '../../../utils/withRestaurant';

const useStyles = makeStyles(styles);

interface SinglePopTypeBubbleOwnProps {
  likedItPopsCount: number;
  lovedItPopsCount: number;
  menuItemName?: string;
  popsCount: number;
  popType: PopType;
  wannaTryPopsCount: number;
  modernLayout: boolean;
}

export interface SinglePopTypeBubbleProps extends SharedPopBubbleProps, SinglePopTypeBubbleOwnProps {}

interface InnerSinglePopTypeBubbleProps extends InnerSharedPopBubbleProps, SinglePopTypeBubbleOwnProps, WithCurrentSessionProps {}

const SinglePopTypeBubble = ({ menuItemName = '', popType, ...props }: InnerSinglePopTypeBubbleProps) => {
  const t = useIntl();
  const classes = useStyles(props);
  const { poppableType, poppableId, modernLayout } = props;

  // the userPops reactive variable keeps a client-side cache of the pop counts at last "pop".
  // this ensures the count updates are not wiped out by server-side/cloudflare menu caching
  const userPops = useReactiveVar(userPopsVar);
  const { i18nKey, mutation, userPopType } = setUpPopType(props);
  const popCounts = userPops[`${poppableType}:${poppableId}`] || buildPopCounts(userPopType, props);
  const selected = userPopType === popType;
  const totalLoved = modernLayout ? (popCounts.loved_it ?? 0) : (popCounts.loved_it ?? 0) + (popCounts.liked_it ?? 0);
  const popTypeCount = popCounts[popType];
  const iconName = popType === 'wanna_try' ? POP_ICONS[`modern_${popType}`] : POP_ICONS[popType];
  const [updatePopMutation, { loading }] = useMutation(mutation);

  return (
    <Tooltip title={t(`pops.pop_types.${popType}.${i18nKey}`)}>
      <Button
        aria-label={`Rate ${menuItemName} as ${t(`pops.pop_types.${popType}.${i18nKey}`)} (${popTypeCount} ${popTypeCount === 1 ? 'rating' : 'ratings'})`}
        className={classNames([classes.popButton, popType === 'loved_it' && 'modern-love-pop-button'])}
        data-cy={popType}
        disabled={loading}
        onClick={
          () => !loading && executeWithProgressBar(
            () => onPopButtonClick(selected, popType, updatePopMutation, props),
          )
        }
        variant="text"
      >
        <Icon
          className={classNames({
            active: selected && popType === 'loved_it',
            [classes.iconPoppedModern]: selected && popType !== 'loved_it',
          })}
          size="large"
          icon={iconName}
        />
        {popType === 'loved_it' && (
        <span>{totalLoved} <FormattedMessage id={`generic.like${totalLoved === 1 ? '' : 's'}`} defaultMessage={totalLoved === 1 ? 'like' : 'likes'} /></span>
        )}
      </Button>
    </Tooltip>
  );
};

export default memo(compose<InnerSinglePopTypeBubbleProps, SinglePopTypeBubbleProps>(
  withCurrentSession,
  connector,
  withRestaurant,
  mapProps<
    InnerSinglePopTypeBubbleProps,
    Omit<InnerSinglePopTypeBubbleProps, 'restaurantId'> & WithRestaurantProps
  >(({ restaurant, ...props }) => ({
    ...props,
    restaurantId: restaurant.id,
  })),
)(SinglePopTypeBubble));
