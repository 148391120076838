import { screenReaderOnly } from '../../../assets/jss/utils/screenReaderOnly';

export default theme => ({
  closeButtonAbsolute: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
    backgroundColor: 'rgba(0, 0, 0, 0.8) !important',
    color: 'white !important',
    left: theme.consumer.useLegacyDishLayout ? theme.spacing(3) : 0,
    padding: theme.spacing(0.5, 1),
    position: 'absolute',
    textTransform: 'none',
    top: theme.consumer.useLegacyDishLayout ? theme.spacing(1) : 0,
    zIndex: 2,
  },
  closeButtonAbsoluteWithoutPhotos: {
    display: 'none !important',
  },
  fullscreenButton: {
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    color: 'white',
    padding: theme.spacing(0.5, 1),
    position: 'absolute',
    right: theme.spacing(1),
    top: 0,
    zIndex: 2,
  },
  galleryContainer: () => {
    if (theme.consumer.useLegacyDishLayout) {
      return {
        [theme.breakpoints.down('md')]: {
          margin: 'auto',
          padding: 0,
        },
        backgroundColor: '#000',
        margin: theme.spacing(-2),
        padding: 0,
        position: 'relative',
      };
    }
    return {
      [theme.breakpoints.down('md')]: {
        borderRight: 'none',
        margin: 0,
        padding: 0,
      },
      borderRight: '1px solid rgba(0, 0, 0, 0.05)',
      margin: theme.spacing(-4, 0),
      padding: theme.spacing(6, 4, 12, 4), // Push up from fixed bottom bar
      position: 'relative',
    };
  },
  galleryContainerBackground: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
    backgroundImage: 'linear-gradient(0deg, rgb(0, 0, 0), rgba(18, 18, 18, 0) 42%), linear-gradient(270deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15) 16%)',
    backgroundPosition: 'center center',
    backgroundSize: 'contain',
    height: '100%',
    left: 0,
    position: 'absolute',
    top: 0,
    width: '100%',
  },
  galleryDot: {
    backgroundColor: '#EFEFEF',
    borderRadius: '50%',
    height: theme.spacing(2),
    margin: theme.spacing(0, 0.8),
    padding: 0,
    position: 'relative',
    width: theme.spacing(2),
    zIndex: 1,
  },
  galleryDotActive: {
    backgroundColor: theme.consumer.primaryColorOnWhite,
  },
  galleryDotsContainer: {
    alignItems: 'center',
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    left: 0,
    padding: theme.spacing(1, 0),
    position: 'absolute',
    width: '100%',
  },
  galleryHeading: {
    ...screenReaderOnly,
  },
  galleryPhoto: () => {
    if (theme.consumer.useLegacyDishLayout) {
      return {
        [theme.breakpoints.down('md')]: {
          borderRadius: 0,
          boxShadow: 'none',
          height: 'auto',
          maxHeight: 'none',
          width: '100%',
        },
        borderRadius: 0,
        boxShadow: 'none',
        position: 'relative',
      };
    }
    return {
      [theme.breakpoints.down('md')]: {
        boxShadow: 'none',
        height: 'auto',
        maxHeight: 'none',
        width: '100%',
      },
      borderRadius: 0,
      boxShadow: '0 0 12px rgba(0, 0, 0, 0.2)',
      position: 'relative',
    };
  },
  galleryPhotoContainer: () => {
    if (theme.consumer.useLegacyDishLayout) {
      return {
        [theme.breakpoints.down('md')]: {
          height: 'auto',
        },
        alignItems: 'center',
        display: 'flex',
        height: '100vh',
        justifyContent: 'center',
        maxHeight: 'none',
        overflow: 'hidden',
        position: 'relative',
      };
    }
    return {
      [theme.breakpoints.down('md')]: {
        height: 'auto',
      },
      alignItems: 'center',
      display: 'flex',
      height: `calc(100vh - ${theme.spacing(18)}px)`, // Subtract galleryContainer vertical padding
      justifyContent: 'center',
      maxHeight: 'none',
      overflow: 'hidden',
      position: 'relative',
    };
  },
  galleryPhotoControlsContainer: {
    [theme.breakpoints.down('md')]: {
      display: 'block',
      width: '100%',
    },
    display: 'inline-block',
    height: 'auto',
    position: 'relative',
    textAlign: 'center',
    width: '100%',
  },
  galleryPhotoHorizontal: {
    maxHeight: theme.consumer.useLegacyDishLayout ? '100vh' : `calc(100vh - ${theme.spacing(14)}px)`,
    objectFit: 'contain',
    width: '100%',
  },
  galleryPhotoVertical: {
    height: theme.consumer.useLegacyDishLayout ? '100vh' : `calc(100vh - ${theme.spacing(14)}px)`,
    maxWidth: '100%',
    objectFit: 'contain',
  },
  modernGalleryPhotoControls: {
    '& > svg': {
      '&:first-child': {
        transform: 'scale(1.5)',
      },
      '&:last-child': {
        transform: 'rotate(180deg) scale(1.5)',
      },
      boxSizing: 'content-box',
      cursor: 'pointer',
      padding: '4px',
    },
    color: '#FFF',
    display: 'flex',
    justifyContent: 'space-between',
    padding: `0 ${theme.spacing(3)}px`,
    position: 'absolute',
    width: '100%',
  },
});
