import React, { createRef, useState, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { Tabs, Tab, Box, Popover } from '@material-ui/core';
import { Typography, Divider } from '@popmenu/common-ui';
import { compose, mapProps } from '@shakacode/recompose';
import { useDispatch, useSelector } from 'react-redux';

import { useHistory } from 'react-router-dom';
import { useQuery } from '~/lazy_apollo/client';
import { createEvent } from '~/utils/eventable';
import menuItemModifierGroupsQuery from '../../../libs/gql/queries/menus/menuItemModifierGroupsQuery.gql';

import { formatPhone, nl2br } from '../../../utils/utils';
import { withRestaurant } from '../../../utils/withRestaurant';
import { withIntl } from '../../../utils/withIntl';
import { classNames, makeStyles } from '../../../utils/withStyles';
import menuItemModernDetailsTabsStyles from './styles';
import menuItemDetailsTabsStyles from '../MenuItemDetailsInfo/styles';
import { openProfileModal, openProfileV2Modal, openVipV2Modal, setVipData } from '../../../shared/ModalActions';

import CustomContent from '../../shared/CustomContent';
import Grid from '../../../shared/Grid';
import ReservationWidget from '../../shared/ReservationWidget';
import ReviewForm from '../../reviews/ReviewForm';
import ScrollableMenuItemReviews from '../ScrollableMenuItemReviews';
import DishExtras from '../DishExtras';
import DishTags from '../DishTags';
import LoadedModifierGroupsProvider from '../cart/LoadedModifierGroupsProvider';
import createMenuItemCartSelectedMenuItemMutation from '../../../libs/gql/mutations/menu_item_carts/createMenuItemCartSelectedMenuItemMutation.gql';
import updateMenuItemCartSelectedMenuItemMutation from '../../../libs/gql/mutations/menu_item_carts/updateMenuItemCartSelectedMenuItemMutation.gql';
import ModernSelectedItemForm from '../cart/ModernSelectedItemForm';
import { AH, AHLevelProvider } from '../../shared/AccessibleHeading';
import { withCurrentSession } from '../../../shared/CurrentSessionProvider';
import Loading from '../../../shared/Loading';
import { executeWithProgressBar } from '../../../utils/postponed';

const useStyles = makeStyles(theme => ({ ...menuItemDetailsTabsStyles(theme), ...menuItemModernDetailsTabsStyles(theme) }));

const RESTAURANT_CUSTOM_TAB = 'RESTAURANT_CUSTOM_TAB';
const LOCATION_CUSTOM_TAB = 'LOCATION_CUSTOM_TAB';
const RESERVE_TAB = 'RESERVE_TAB';
const REVIEW_TAB = 'REVIEW_TAB';
const DETAILS_TAB = 'DETAILS_TAB';

const showDetailsTab = (restaurant, menuItem, showAddToCartButton) => (
  restaurant.featureSetting.isOrderingEnabled && (
    showAddToCartButton ||
    menuItem.description ||
    menuItem.dish.allSelectedDishTags.length > 0 ||
    menuItem.extras.length > 0 ||
    menuItem.sharedExtraGroups.length > 0 ||
    menuItem.extraGroups.length > 0
  )
);

const defaultTab = (props) => {
  let selectedTab = 'DETAILS_TAB';
  switch (props.theme.defaultDishDetailTab) {
    case 'details_tab':
      if (!showDetailsTab(props.restaurant, props.menuItem, props.showAddToCartButton)) {
        selectedTab = 'REVIEW_TAB';
      }
      return selectedTab;
    case 'review_tab':
      return 'REVIEW_TAB';
    case 'extra_tab':
      if (props.theme.dishDetailTab === 'reservations') {
        return 'RESERVE_TAB';
      } else if (props.theme.dishDetailTab !== 'empty' && props.menuItem.section.menu.location.customDishTabName && props.menuItem.section.menu.location.customDishTabContent) {
        return 'LOCATION_CUSTOM_TAB';
      } else if (props.theme.dishDetailTab !== 'empty' && props.menuItem.restaurant.customDishTabName && props.menuItem.restaurant.customDishTabContent) {
        return 'RESTAURANT_CUSTOM_TAB';
      }
      break;
    default:
      return 'DETAILS_TAB';
  }
  return 'DETAILS_TAB';
};

const MenuItemModernDetailsTabs = (props) => {
  const classes = useStyles(props);
  const menuItemCart = useSelector(state => state.menuItemCart);
  const dispatch = useDispatch();
  const [popoverAnchor, setPopoverAnchor] = useState(null);
  const [selectedTab, setSelectedTab] = useState(defaultTab(props));
  const [keyRenderReviews, setKeyRenderReviews] = useState(0);

  const panelRef = createRef();

  const history = useHistory();

  const scrollToReviewForm = () => {
    const scrollContainer = document.getElementById('pm-item-scroll-container');
    scrollContainer.style.scrollBehavior = 'smooth';
    scrollContainer.scrollTop = window.innerHeight / 2;
  };

  const renderTab = (tab) => {
    const { reviewsCount } = props.menuItem;
    let tabName;
    switch (tab) {
      case DETAILS_TAB:
        tabName = 'Details';
        break;
      case LOCATION_CUSTOM_TAB:
        tabName = props.menuItem.section.menu.location.customDishTabName;
        break;
      case RESERVE_TAB:
        tabName = 'Book a Table';
        break;
      case RESTAURANT_CUSTOM_TAB:
        tabName = props.menuItem.restaurant.customDishTabName;
        break;
      case REVIEW_TAB:
        tabName = reviewsCount > 0 ? `Reviews (${reviewsCount})` : 'Reviews (0)';
        break;
      default:
        return null;
    }
    return (
      <Tab
        key={tab}
        className={classNames([classes.tab, classes.modernTab, 'pm-menu-item-tab'])}
        label={tabName}
        data-cy-tab-type={tab}
        value={tab}
      />
    );
  };

  const openReservations = () => {
    props.closeMenuItemModal();
    history.push('/reservations');
  };

  const openWaitlist = () => {
    props.closeMenuItemModal();
    history.push('/waitlist');
  };

  const hasFullyEnabledOnlineOrdering = useMemo(() => {
    const hasEnabledOnlineOrderingPage = props.restaurant.customPages.find(page => (
      page.isEnabled && page.hasOnlineOrderingSection
    ));
    return hasEnabledOnlineOrderingPage && props.restaurant.featureSetting.isOrderingEnabled;
  }, [props.restaurant.customPages, props.restaurant.featureSetting.isOrderingEnabled]);

  const openVipOrProfile = () => {
    if (props.isUserLogged) {
      if (props.restaurant.featureSetting.isProfileV2Enabled) {
        dispatch(openProfileV2Modal('my_profile'));
      } else {
        dispatch(openProfileModal());
      }
    } else {
      dispatch(setVipData());
      dispatch(openVipV2Modal());
    }
  };

  const buildVipProfileAction = (forceButton = false) => (
    {
      action: openVipOrProfile,
      forceButton,
      title: (props.isUserLogged ? <FormattedMessage id="profile.nav_item_my_profile" defaultMessage="My Profile" /> : <FormattedMessage id="sessions.sign_up_title" defaultMessage="Become a VIP" />),
    }
  );

  // the action button is what will display in the bottom of the menu item details modal when **NOT** in online ordering
  const getActionButton = () => {
    const { theme, restaurant } = props;
    switch (theme.dishDetailActionButton) {
      case 'order_btn':
        // if ordering is not fully activated and you are not literally in online ordering
        if (!hasFullyEnabledOnlineOrdering && !props.showAddToCartButton) {
          const forceButton = true;
          return buildVipProfileAction(forceButton);
        }
        return null; // the ordering buttons are complex and handled downstream
      case 'vip_btn':
        return buildVipProfileAction();
      case 'waitlist_btn':
        return {
          action: openWaitlist,
          forceButton: false,
          title: <FormattedMessage id="dishes.details.join_the_waitlist" defaultMessage="Join the Waitlist" />,
        };
      case 'reservations_btn':
        return {
          action: ({ target }) => (restaurant.featureSetting.isReservationsEnabled ? openReservations() : setPopoverAnchor(target?.parentNode)),
          forceButton: false,
          title: <FormattedMessage id="dishes.details.book_a_table" defaultMessage="Make a Reservation" />,
        };
      default:
        return null;
    }
  };

  const getReservationWidget = (skipPopover = false) => {
    const { menuItem } = props;
    const { menu } = menuItem.section;
    const { location } = menu;
    const reservationWidget = (!!(location.openTableId || location.resyVenueId || location.yelpFriendlyId) && (
      <ReservationWidget
        eventableId={menuItem.id}
        eventableType="MenuItem"
        location={location}
        widgetId={`menu-item-${menuItem.id}`}
        skipPopover={skipPopover}
      />
    ));
    return reservationWidget;
  };

  const renderReserveTab = () => {
    const { menuItem, restaurantId, t } = props;
    const { menu } = menuItem.section;
    const { location } = menu;
    if (!location.allowReservations || !menu.allowReservation) {
      return null;
    }

    const reservationWidget = getReservationWidget();

    return (
      <section className={classNames([classes.reserveContainer, classes.modernReserveContainer])}>
        <AH typography className={classNames([classes.reserveHeading, classes.modernReserveHeading])} variant="h4">
          <FormattedMessage
            id="dishes.details.reserve_heading"
            defaultMessage="Ready to join us? Great!"
          />
        </AH>
        <AHLevelProvider>
          {reservationWidget && (
            <React.Fragment>
              {reservationWidget}
              <div style={{ position: 'relative' }}>
                <Divider className={classes.modernReserveDivider} />
                <span>
                  <FormattedMessage id="generic.or" defaultMessage="or" />
                </span>
              </div>
            </React.Fragment>
          )}
          <Typography className={classes.reserveContent}>
            {t('dishes.details.reserve_body')}
          </Typography>
          {location.phone && (
            <Typography className={classes.reserveCall}>
              <a
                aria-label={`${location.phone} ${props.t('generic.telephone')}`}
                href={`tel:${location.phone}`}
                onClick={() => {
                  createEvent({
                    eventableId: menuItem.id,
                    eventableType: 'MenuItem',
                    eventType: 'call_attempt_event',
                    restaurantId,
                  });
                }}
              >
                {formatPhone(location.phone) || location.phone}
              </a>
            </Typography>
          )}
        </AHLevelProvider>
      </section>
    );
  };

  const reviewTab = () => (
    <span className={selectedTab !== REVIEW_TAB && classes.hidden}>
      <React.Fragment>
        <aside>
          <AH typography className={classNames(classes.reviewHeading, 'pm-menu-item-details-review-heading')} variant="h3">
            <FormattedMessage id="menu_item_details_info.tried_this_item_modern" defaultMessage="Had it already? Leave a review" />
          </AH>
          <Typography className={classNames(classes.reviewContent, 'pm-menu-item-details-review-content')}>
            <FormattedMessage id="menu_item_details_info.add_your_review" defaultMessage="Add your review below to help others know what to expect." />
          </Typography>
        </aside>
        <AHLevelProvider>
          <ReviewForm
            dishId={props.menuItem.dishId}
            menuItem={props.menuItem}
            restaurant={props.menuItem.restaurant}
            modernLayout
            callback={() => setKeyRenderReviews(new Date().getUTCMilliseconds())}
          />
        </AHLevelProvider>
      </React.Fragment>
      <div key={keyRenderReviews} className={classes.reviewsContainer}>
        <ScrollableMenuItemReviews
          includeItemLink={props.includeItemLink}
          menuItem={props.menuItem}
          restaurantId={props.restaurantId}
          showReviewForm={() => scrollToReviewForm()}
        />
      </div>
    </span>
  );

  const renderPanel = () => {
    const { theme, menuItem, restaurant, selectedMenuItem } = props;

    switch (selectedTab) {
      case RESTAURANT_CUSTOM_TAB:
        return (
          <CustomContent
            html={props.menuItem.restaurant.customDishTabContent}
            id="custom-tab"
          />
        );
      case LOCATION_CUSTOM_TAB:
        return (
          <CustomContent
            html={props.menuItem.section.menu.location.customDishTabContent}
            id="location-custom-tab"
          />
        );
      case RESERVE_TAB:
        return renderReserveTab();
      case DETAILS_TAB:
        return (
          <div className={classes.modernDetailsContainer}>
            {menuItem.description && (
              <Typography className={classNames(classes.dishDescription, 'pm-menu-item-details-description')}>
                {nl2br(menuItem.description)}
              </Typography>
            )}
            {(menuItem.htmlContent && restaurant.featureSetting.isDishCustomHtmlEnabled) && (
              <React.Fragment>
                <br />
                <CustomContent id="custom_dish_html_content" html={menuItem.htmlContent} />
              </React.Fragment>
            )}
            {!props.showAddToCartButton && !selectedMenuItem && (
              <DishExtras
                classes={classes}
                currency={menuItem.currency}
                displayExtraGroups={theme.showMenuItemExtraGroups}
                extraGroups={menuItem.extraGroups}
                extras={menuItem.extras}
                sharedExtraGroups={menuItem.sharedExtraGroups}
                showCurrencySymbol={theme.showCurrencySymbol}
                sizes={menuItem.sizes}
              />
            )}
            <Box display="flex" justifyContent="flex-start" className="dish-tags-modern">
              <DishTags
                allSelectedDishTags={menuItem.dish.allSelectedDishTags}
                classes={classes}
              />
            </Box>
            {(props.showAddToCartButton || selectedMenuItem) && (
              <ModernSelectedItemForm
                type="inner"
                modernLayout
                closeModal={() => { }}
                menuItem={menuItem}
                menuItemCartId={menuItemCart.menuItemCartId}
                menuItemCartIsScheduled={menuItemCart.menuItemCartIsScheduled}
                menuItemCartScheduledAt={menuItemCart.menuItemCartScheduledAt}
                menuItemCartType={menuItemCart.menuItemCartType}
                selectedMenuItem={selectedMenuItem}
                selectedMenuItemMutation={selectedMenuItem ? updateMenuItemCartSelectedMenuItemMutation : createMenuItemCartSelectedMenuItemMutation}
              />
            )}
          </div>
        );
      default:
        return null;
    }
  };

  const { menuItem, restaurant, theme, selectedMenuItem } = props;
  const { menu } = menuItem.section;
  const { location } = menu;
  // Only include enabled tabs
  const tabs = [REVIEW_TAB];

  switch (theme.dishDetailTab) {
    case 'empty':
      break;
    case 'reservations':
      if (location.allowReservations && menu.allowReservation) tabs.unshift(RESERVE_TAB);
      break;
    case 'custom':
      if (location.customDishTabName && location.customDishTabContent) {
        tabs.unshift(LOCATION_CUSTOM_TAB);
      } else if (menuItem.restaurant.customDishTabName && menuItem.restaurant.customDishTabContent) {
        tabs.unshift(RESTAURANT_CUSTOM_TAB);
      }
      break;
    default:
      break;
  }

  if (showDetailsTab(restaurant, menuItem, props.showAddToCartButton)) tabs.unshift(DETAILS_TAB);

  const itemFormProps = {
    closeModal: props.closeMenuItemModal,
    menuItem,
    menuItemCartId: menuItemCart.menuItemCartId,
    menuItemCartIsScheduled: menuItemCart.menuItemCartIsScheduled,
    menuItemCartScheduledAt: menuItemCart.menuItemCartScheduledAt,
    menuItemCartType: menuItemCart.menuItemCartType,
    modernLayout: true,
    onSubmitComplete: props.onSubmitComplete,
    selectedMenuItem,
    selectedMenuItemMutation: selectedMenuItem ? updateMenuItemCartSelectedMenuItemMutation : createMenuItemCartSelectedMenuItemMutation,
  };

  const reservationWidget = getReservationWidget(setPopoverAnchor);
  const { data: modGroupsData, loading: loadingModifierGroupsData } = useQuery(
    menuItemModifierGroupsQuery,
    {
      variables: {
        menuItemId: menuItem.id,
      },
    },
  );

  if (loadingModifierGroupsData) {
    return <Loading />;
  }

  const modifierGroupsData = modGroupsData?.menuItem?.modifierGroups;

  return (
    <LoadedModifierGroupsProvider initialModifierGroups={modifierGroupsData}>
      <ModernSelectedItemForm
        type="outer"
        {...itemFormProps}
      >
        <section className={classes.tabsContainer}>
          <Grid container>
            {tabs.length > 1 && (
              <Grid className={classes.tabsSticky} item xs={12}>
                <Tabs
                  onChange={(e, selectedTabChanged) => {
                    executeWithProgressBar(() => {
                      panelRef.current?.focus();
                      setSelectedTab(selectedTabChanged);
                      createEvent({
                        eventableId: menuItem.id,
                        eventableType: 'MenuItem',
                        eventType: `${selectedTabChanged.toLowerCase()}_event`,
                        restaurant: restaurant.id,
                      });
                    });
                  }}
                  className={classNames(classes.modernTabsContainer, 'pm-menu-item-tabs')}
                  value={selectedTab}
                  variant="fullWidth"
                  TabIndicatorProps={{
                    style: {
                      backgroundColor: theme.primaryColor || '#000',
                    },
                  }}
                >
                  {tabs.map(tab => renderTab(tab))}
                </Tabs>
              </Grid>
            )}
            <Grid item xs={12} style={{ paddingBottom: 0 }} className={tabs.length > 1 ? classes.modernTabContentWrapperWithTabs : classes.modernTabContentWrapper}>
              <div ref={panelRef} tabIndex="-1">
                {renderPanel()}
                {reviewTab()}
              </div>
            </Grid>
            {props.showOrderLink && (
              <ModernSelectedItemForm
                type="submit"
                submitButton={getActionButton()}
                openModernMenuCartModal={props.openModernMenuCartModal}
                openModalInCart={props.openModalInCart}
                showAddToCartButton={props.showAddToCartButton}
                orderable={props.orderable}
                {...itemFormProps}
              />
            )}
            <Popover
              id={'reservations-popover'}
              anchorEl={popoverAnchor}
              anchorOrigin={{
                horizontal: 'left',
                vertical: 'bottom',
              }}
              transformOrigin={{
                horizontal: 'left',
                vertical: 'bottom',
              }}
              getContentAnchorEl={null}
              onClose={() => setPopoverAnchor(null)}
              open={Boolean(popoverAnchor)}
              className={menuItem?.section?.menu?.location?.resyVenueId ? classes.reservationPopoverHidden : classes.reservationPopover}
            >
              {reservationWidget}
            </Popover>
          </Grid>
        </section>
      </ModernSelectedItemForm>
    </LoadedModifierGroupsProvider>
  );
};

MenuItemModernDetailsTabs.propTypes = {
  hasPhoto: PropTypes.bool.isRequired,
  includeItemLink: PropTypes.bool.isRequired,
  isLayoutB: PropTypes.bool.isRequired,
  isUserLogged: PropTypes.bool.isRequired,
  menuItem: PropTypes.shape({
    dishId: PropTypes.number,
    id: PropTypes.number,
    restaurant: PropTypes.shape({
      customDishTabContent: PropTypes.string,
      customDishTabName: PropTypes.string,
    }).isRequired,
    reviews: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
    })),
    section: PropTypes.shape({
      menu: PropTypes.shape({
        allowReservation: PropTypes.bool,
        location: PropTypes.shape({
          allowReservations: PropTypes.bool,
          customDishTabContent: PropTypes.string,
          customDishTabName: PropTypes.string,
        }),
      }),
    }),
  }).isRequired,
  openModernMenuCartModal: PropTypes.func.isRequired,
  openReviewModal: PropTypes.func.isRequired,
  restaurantId: PropTypes.number.isRequired,
  showOrderLink: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  theme: PropTypes.object.isRequired,
};

export default compose(
  withCurrentSession,
  withIntl,
  withRestaurant,
  mapProps(({ currentSession, restaurant, ...props }) => ({
    ...props,
    isUserLogged: !!currentSession.user,
    restaurant,
    restaurantId: restaurant.id,
  })),
)(MenuItemModernDetailsTabs);
