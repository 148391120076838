import React from 'react';
import PropTypes from 'prop-types';
import ReactSelectAsync from 'react-select/async';
import ReactSelect from 'react-select';
import { compose } from '@shakacode/recompose';
import {
  Chip,
  InputAdornment as MuiInputAdornment,
  MenuItem,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import FormHelperText from '@material-ui/core/FormHelperText';
import { withTheme } from '@material-ui/core/styles';
import { Box, Icon } from '@popmenu/common-ui';
import { Download, X as XIcon } from '@popmenu/web-icons';

import { withIntl } from '../../../utils/withIntl';
import { classNames, withStyles } from '../../../utils/withStyles';
import selectStyles from '../../../assets/jss/shared/selectStyles';
import { findElement, findElements } from '../../../utils/dom';

export const makeClearValueIndicatorFocusable = (wrapperRef, selectComponentRef) => {
  const clearValueIndicator = findElement('[class*="indicatorContainer"][aria-hidden="true"]', wrapperRef);

  if (clearValueIndicator) {
    clearValueIndicator.role = 'button';
    clearValueIndicator.ariaLabel = 'Close';
    clearValueIndicator.tabIndex = 0;
    clearValueIndicator.removeAttribute('aria-hidden');
    clearValueIndicator.onkeydown = function clearValues(e) {
      // Prevent default behavior that opens select options
      e.preventDefault();

      // Clear values and focus on input
      if (e.key === 'Enter' || e.key === ' ') {
        selectComponentRef.select.clearValue();
        wrapperRef.querySelector('input:not([type="hidden"])')?.focus();
      }
    };
  }
};

const NoOptionsMessage = props => (
  <Typography
    color="textSecondary"
    className={props.selectProps.classes.noOptionsMessage}
    {...props.innerProps}
  >
    {props.selectProps.noOptionsMessageProps.label}
  </Typography>
);

const inputComponent = ({ inputRef, ...props }) => <div ref={inputRef} {...props} />;

const Control = (props) => {
  // Start Adornment
  let startAdornment = null;
  if (props.selectProps && props.selectProps.startIcon) {
    startAdornment = (
      <MuiInputAdornment position="start">
        {typeof props.selectProps.startIcon === 'string' ?
          <Icon icon={props.selectProps.startIcon} /> :
          React.createElement(props.selectProps.startIcon, props.selectProps.startIconProps)}
      </MuiInputAdornment>
    );
  }

  return (
    <TextField
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          children: props.children,
          className: props.selectProps.classes.input,
          inputRef: props.innerRef,
          ...props.innerProps,
        },
        startAdornment,
      }}
      {...props.selectProps.textFieldProps}
    />
  );
};

const Option = props => (
  <MenuItem
    disabled={props.data.disabled}
    className={props.selectProps.wrap ? props.selectProps.classes.wrap : null}
    component="div"
    ref={props.innerRef}
    selected={props.isFocused}
    style={{
      fontWeight: props.isSelected ? 500 : 400,
    }}
    {...props.innerProps}
  >
    {props.selectProps.hoverOptions ? (
      <Tooltip title={props.label}>
        <Box data-cy="option">
          <Box display="flex">
            {props.data.useIcon && (
              <Box mr={1}><Icon icon={Download} color={props.data.iconColor || 'default'} /></Box>
            )}
            {props.children}
          </Box>
          {props.data.useSubtitle && (
            <Box ml={3}>
              <Typography style={{ color: props.theme.colors.neutral30 }} variant="body2">
                {props.data.subtitle}
              </Typography>
            </Box>
          )}
        </Box>
      </Tooltip>
    ) : (
      <Box data-cy="option">
        {props.children}
      </Box>
    )}
  </MenuItem>
);

const Placeholder = props => (
  <Typography
    color="inherit"
    className={props.selectProps.classes.placeholder}
    {...props.innerProps}
  >
    {props.children}
  </Typography>
);

const SingleValue = props => (
  <Typography
    className={classNames(
      props.selectProps.classes.singleValue,
      props.selectProps.wrap ? props.selectProps.classes.wrap : null)}
    color="inherit"
    {...props.innerProps}
  >
    {props.children}
  </Typography>
);

const ValueContainer = props => (
  <div
    className={classNames(
      props.selectProps.classes.valueContainer,
      props.selectProps.wrap ? props.selectProps.classes.wrap : null,
    )}
    data-cy={props.selectProps['data-cy']}
  >
    {props.children}
  </div>
);

const MultiValue = props => (
  <Chip
    tabIndex={-1}
    label={props.children}
    className={classNames(props.selectProps.classes.chip, {
      [props.selectProps.classes.chipFocused]: props.isFocused,
    })}
    onDelete={props.selectProps.isChipClearable === false ? undefined : props.removeProps.onClick}
    deleteIcon={<Icon icon={XIcon} {...props.removeProps} />}
    size="small"
    style={props.selectProps.chipStyles}
  />
);

const Menu = props => (
  <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
    {props.children}
  </Paper>
);

/*
  Reference react-select when setting additional options: https://github.com/JedWatson/react-select
 */
class Select extends React.PureComponent {
  constructor(props) {
    super(props);
    this.fixAccessibility = this.fixAccessibility.bind(this);
    this.getTitle = this.getTitle.bind(this);
    this.wrapperRef = React.createRef();
    this.selectComponentRef = React.createRef();
    this.accessibilityTimeout = null;
  }

  componentDidMount() {
    this.accessibilityTimeout = setTimeout(() => this.fixAccessibility(), 250);
  }

  componentDidUpdate() {
    this.fixAccessibility();
  }

  componentWillUnmount() {
    clearTimeout(this.accessibilityTimeout);
  }

  getAriaLabel() {
    if (this.props['aria-label']) {
      return this.props['aria-label'];
    }
    const title = this.getTitle();
    if (title && typeof title === 'string') {
      return title;
    }
    return this.props.placeholder;
  }

  getTitle() {
    const selectTitle = this.props.title;
    return selectTitle && this.props.isRequiredTitleLabel ?
      `${selectTitle} (${this.props.t('forms.required')})` :
      selectTitle;
  }

  fixAccessibility() {
    const wrapperRef = this.wrapperRef.current;

    if (wrapperRef) {
      // Fix dummy input provided to non-searchable selects
      // An aria-live region reads out when the input is focused, but Lighthouse complains anyway
      findElements('input', wrapperRef).forEach((input) => {
        if (input.readOnly && !input.getAttribute('aria-label')) {
          const label = this.getAriaLabel();
          input.setAttribute('aria-label', label);
        }
      });

      // Allow focusing on X clear symbol
      // Fix for https://github.com/JedWatson/react-select/issues/4988
      const selectComponentRef = this.selectComponentRef.current;
      makeClearValueIndicatorFocusable(wrapperRef, selectComponentRef);
    }
  }

  render() {
    const Component = this.props.async ? ReactSelectAsync : ReactSelect;
    const optionComponent = typeof this.props.optionComponent === 'function' ?
      this.props.optionComponent :
      Option;

    if (!Component) {
      return null;
    }
    let value;
    if (this.props.options) {
      if (this.props.isMulti) {
        value = (this.props.value || []).map(v => (
          this.props.options.find(option => option.value === v) || { label: v, value: v }
        ));
      } else {
        value = this.props.options.find(
          option => option.value === this.props.value,
        ) || this.props.value;
      }
    } else {
      value = this.props.value;
    }

    // console.log(`Select.render ${this.props.name}`);
    return (
      <div
        className={classNames(this.props.className, this.props.classes.root)}
        ref={this.wrapperRef}
      >
        <Component
          ref={this.selectComponentRef}
          aria-label={this.getAriaLabel()}
          cacheOptions={this.props.async ? true : undefined}
          chipStyles={this.props.chipStyles}
          {...(this.props.maxMenuHeight ? { maxMenuHeight: this.props.maxMenuHeight } : {})}
          classes={{
            ...this.props.classes,
            input: classNames(
              this.props.classes.input,
              this.props.classes[`input-${this.props.variant}`],
              this.props.inputClassName,
              this.props.disabled ? this.props.classes.disabled : null,
            ),
          }}
          components={{
            Control,
            Menu,
            MultiValue,
            NoOptionsMessage,
            Option: optionComponent,
            Placeholder,
            SingleValue,
            ValueContainer,
            ...(this.props.hideDropdownIcon ? {
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
            } : {}),
          }}
          data-cy={this.props['data-cy']}
          defaultOptions={this.props.async ? true : undefined}
          hideSelectedOptions={this.props.hideSelectedOptions}
          hoverOptions={this.props.hoverOptions}
          innerRef={this.props.innerRef}
          isClearable={typeof this.props.isClearable !== 'undefined' ? this.props.isClearable : this.props.isMulti}
          isChipClearable={this.props.isChipClearable}
          isDisabled={this.props.disabled}
          isMulti={this.props.isMulti}
          isSearchable={this.props.isSearchable}
          instanceId={this.props.name}
          loadOptions={this.props.loadOptions}
          name={this.props.name}
          noOptionsMessageProps={{
            label: this.props.noOptionsMessage,
          }}
          onChange={this.props.onChange}
          onMenuClose={this.props.onMenuClose}
          onMenuOpen={this.props.onMenuOpen}
          options={this.props.options}
          placeholder={this.props.placeholder}
          role="combobox"
          simpleValue
          startIcon={this.props.startIcon}
          startIconProps={this.props.startIconProps}
          menuPortalTarget={this.props.menuPortalTarget}
          styles={{
            clearIndicator: () => ({
              color: 'rgba(0, 0, 0, 0.5)',
            }),
            dropdownIndicator: () => ({
              color: this.props.theme.palette.secondary.main,
            }),
            indicatorSeparator: () => ({
              display: 'none',
            }),
            input: base => ({
              ...base,
              '& input': {
                color: 'inherit',
                font: 'inherit',
              },
              color: 'inherit',
            }),
            menuPortal: base => ({ ...base, zIndex: 9999999 }),
          }}
          textFieldProps={{
            'data-testid': this.props['data-testid'],
            'data-tour-id': this.props['data-tour-id'],
            InputLabelProps: {
              className: this.props.disabled ? this.props.classes.disabledLabel : null,
              shrink: true,
            },
            label: this.getTitle(),
            variant: this.props.variant,
          }}
          value={value}
          wrap={this.props.wrap}
          filterOption={this.props.filterOption}
        />
        {this.props.helperText && (
          <FormHelperText variant="outlined">
            {this.props.helperText}
          </FormHelperText>
        )}
      </div>
    );
  }
}

Select.defaultProps = {
  'aria-label': null,
  async: false,
  chipStyles: {},
  className: null,
  'data-cy': null,
  'data-tour-id': null,
  disabled: false,
  filterOption: undefined,
  helperText: null,
  hideDropdownIcon: false,
  hideSelectedOptions: false,
  hoverOptions: false,
  innerRef: undefined,
  inputClassName: null,
  isChipClearable: undefined,
  isClearable: undefined,
  isMulti: false,
  isRequiredTitleLabel: false,
  isSearchable: true,
  loadOptions: undefined,
  maxMenuHeight: null,
  name: null,
  noOptionsMessage: 'No options',
  onMenuClose: null,
  onMenuOpen: null,
  options: undefined,
  placeholder: null,
  startIcon: null,
  startIconProps: null,
  title: undefined,
  value: null,
  variant: 'outlined',
  wrap: false,
};

Select.propTypes = {
  'aria-label': PropTypes.string,
  async: PropTypes.bool,
  chipStyles: PropTypes.object,
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  'data-cy': PropTypes.string,
  'data-tour-id': PropTypes.string,
  disabled: PropTypes.bool,
  filterOption: PropTypes.func,
  helperText: PropTypes.string,
  hideDropdownIcon: PropTypes.bool,
  hideSelectedOptions: PropTypes.bool,
  hoverOptions: PropTypes.bool,
  innerRef: PropTypes.func,
  inputClassName: PropTypes.string,
  isChipClearable: PropTypes.bool,
  isClearable: PropTypes.bool,
  isMulti: PropTypes.bool,
  isRequiredTitleLabel: PropTypes.bool,
  isSearchable: PropTypes.bool,
  loadOptions: PropTypes.func,
  maxMenuHeight: PropTypes.number,
  name: PropTypes.string,
  // MaybeFormattedMessage
  noOptionsMessage: PropTypes.node,
  onChange: PropTypes.func.isRequired,
  onMenuClose: PropTypes.func,
  onMenuOpen: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  })),
  // MaybeFormattedMessage
  placeholder: PropTypes.node,
  startIcon: PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.string]),
  startIconProps: PropTypes.object,
  t: PropTypes.func.isRequired,
  theme: PropTypes.object.isRequired,
  // MaybeFormattedMessage
  title: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]),
  value: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ])),
    PropTypes.number,
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
    PropTypes.string,
  ]),
  variant: PropTypes.oneOf(['filled', 'outlined', 'standard']),
  wrap: PropTypes.bool,
};

export default compose(
  withTheme,
  withStyles(selectStyles, { withTheme: true }),
  withIntl,
)(Select);
