import { readableFontColor } from '../../../utils/colors';

export default theme => ({
  dishPrice: {
    fontSize: '1.5em',
    padding: '0 16px 0 16px',
    textAlign: 'left',
  },
  modernAdjustPaddingLayoutB: {
    marginTop: 0,
  },
  modernCloseButton: {
    position: 'fixed',
  },
  modernCloseButtonContainer: {
    position: 'absolute',
    right: '144px',
    top: '16px',
  },
  modernCloseButtonContainerLayoutB: {
    position: 'absolute',
    right: '144px',
    top: 0,
  },
  modernCloseButtonContainerNoPhoto: {
    right: '160px',
    top: '16px',
  },
  modernCloseButtonContainerNoPhotoLayoutB: {
    right: '160px',
    top: 0,
  },
  modernCloseIcon: {
    '&:hover': {
      backgroundColor: '#EFEFEF',
    },
    background: '#FFF',
    boxShadow: '0px 2px 3px 1px rgb(0 0 0 / 25%)',
    height: theme.spacing(4),
    position: 'fixed',
    width: theme.spacing(4),
    zIndex: 999,
  },
  modernCloseIconContainer: {
    '& .MuiIconButton-label': {
      width: 'auto',
    },
    position: 'absolute',
    right: '48px',
    top: '16px',
  },
  modernCloseIconContainerLayoutB: {
    '& .MuiIconButton-label': {
      width: 'auto',
    },
    position: 'absolute',
    right: '48px',
    top: 0,
  },
  modernCloseIconContainerNoPhoto: {
    '& .MuiIconButton-label': {
      width: 'auto',
    },
    [theme.breakpoints.down('xs')]: {
      right: '64px',
    },
    top: '16px',
  },
  modernCloseIconContainerNoPhotoLayoutB: {
    '& .MuiIconButton-label': {
      width: 'auto',
    },
    [theme.breakpoints.down('xs')]: {
      right: '64px',
    },
    top: 0,
  },
  modernDishDetailsContainer: {
    [theme.breakpoints.down('xs')]: {
      height: '100vh',
    },
    '& .dish-tags-modern': {
      [theme.breakpoints.down('sm')]: {
        paddingLeft: theme.spacing(3),
      },
      marginBottom: theme.spacing(4),
    },
    '&.layoutA': {
      '& .gallery-container-modern': {
        '& > div': {
          '& img': {
            maxHeight: '300px',
            objectFit: 'cover',
            width: '100%',
          },
          height: '100%',
        },
        border: 'none',
        height: '100%',
        marginBottom: theme.spacing(2),
      },
    },
    height: '90vh',
    overflowX: 'hidden',
    overflowY: 'auto',
    paddingBottom: 0,
  },
  modernDishDetailsResetPadding: {
    marginTop: '-16px',
    padding: 0,
  },
  modernDishDetailsResetPaddingLayoutB: {
    [theme.breakpoints.down('md')]: {
      marginTop: '-16px',
    },
    marginTop: 0,
    padding: 0,
  },
  modernDishName: {
    fontSize: '1.5em',
    fontWeight: 'bold',
    padding: '0 16px 0 16px',
    textAlign: 'center',
  },
  modernIcons: {
    padding: '0 16px 0 16px',
  },
  modernInfoTop: {
    background: 'white',
    height: 'auto',
    margin: '0 !important',
    paddingBottom: '16px',
    position: 'sticky',
    top: 0,
    zIndex: 2,
  },

  modernLocationName: {
    color: readableFontColor('#FFFFFF', '#9E9E9E'),
    fontSize: '14px',
    padding: '0 16px 0 16px',
    textAlign: 'left',
  },
});
