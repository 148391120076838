import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button, Icon } from '@popmenu/common-ui';
import { ArrowLeft } from '@popmenu/web-icons';
import { FormattedMessage } from 'react-intl';
import { useCallback } from 'react';
import { executeWithProgressBar } from '../../utils/postponed';

const MenuItemCloseButton = ({ closeMenuItemModal, menuItem, ...props }) => {
  const handleClick = useCallback(() => {
    executeWithProgressBar(closeMenuItemModal);
  }, [closeMenuItemModal]);

  if (closeMenuItemModal) {
    return (
      <Button
        {...props}
        aria-label="Back To Menu"
        startIcon={<Icon icon={ArrowLeft} />}
        onClick={handleClick}
        size="large"
        variant="text"
      >
        <FormattedMessage id="menu_item.back_to_menu" defaultMessage={'Back To Menu'} />
      </Button>
    );
  }
  return (
    <Button
      {...props}
      aria-label="Back To Menu"
      component={Link}
      startIcon={<Icon icon={ArrowLeft} />}
      size="large"
      to={menuItem.section.menu.menuPageUrl}
      variant="text"
    >
      <FormattedMessage id="menu_item.back_to_menu" defaultMessage={'Back To Menu'} />
    </Button>
  );
};

MenuItemCloseButton.defaultProps = {
  className: null,
  closeMenuItemModal: null,
};

MenuItemCloseButton.propTypes = {
  className: PropTypes.string,
  closeMenuItemModal: PropTypes.func,
  menuItem: PropTypes.shape({
    section: PropTypes.shape({
      menu: PropTypes.shape({
        menuPageUrl: PropTypes.string,
      }),
    }),
  }).isRequired,
};

export default MenuItemCloseButton;
