import React from 'react';
import PropTypes from 'prop-types';
import { compose } from '@shakacode/recompose';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { addMinutes, isBefore, parseISO } from 'date-fns';
import { FormattedMessage } from 'react-intl';
import { Button, Icon, Typography } from '@popmenu/common-ui';
import { CartPlus } from '@popmenu/web-icons';

import { createEvent } from '~/utils/eventable';
import { useFeatureFlags } from '~/utils/featureFlagsContext';
import { formatCurrency } from '../../../utils/currency';
import { nl2br } from '../../../utils/react';
import { usePopmenuConfig } from '../../../utils/withPopmenuConfig';
import { withRestaurant } from '../../../utils/withRestaurant';
import { withTheme } from '../../../utils/withTheme';
import { classNames, makeStyles } from '../../../utils/withStyles';
import menuItemDetailsInfoStyles from './styles';

import CustomContent from '../../shared/CustomContent';
import DishExtras from '../DishExtras';
import DishTags from '../DishTags';
import LegacyMenuItemDetailsInfo from '../LegacyMenuItemDetailsInfo';
import MenuItemCloseButton from '../MenuItemCloseButton';
import MenuItemDetailsTabs from '../MenuItemDetailsTabs';
import PopBubble from '../../pops/PopBubble';
import PopList from '../../pops/PopList';
import ShareButton from '../../shared/ShareButton';
import { setSelectedMenuItem } from '../../../shared/MenuItemCartActions';

import { isOutOfStock, isSoldOut } from '../MenuHelpers';
import { AH, AHLevelProvider } from '../../shared/AccessibleHeading';
import { executeWithProgressBar } from '../../../utils/postponed';

const useStyles = makeStyles(menuItemDetailsInfoStyles);

const MenuItemDetailsInfo = (props) => {
  const {
    closeMenuItemModal,
    menuEnabled,
    menuItem,
    menuOrderingUrl,
    openModalInCart,
    openReviewModal,
    restaurant,
    showAddToCartButton,
    theme,
  } = props;
  const { isFeatureActive } = useFeatureFlags();
  const isLocationCustomTabDefault = isFeatureActive('location_custom_tab_is_default');
  const isNewOrdering = isFeatureActive('popmenu_ordering_app');
  const dispatch = useDispatch();
  const classes = useStyles(props);
  const { widget } = usePopmenuConfig();
  const menuEmbed = !!widget;
  const isScheduled = useSelector(state => state.menuItemCart.menuItemCartIsScheduled);
  const scheduledAt = useSelector(state => state.menuItemCart.menuItemCartScheduledAt);

  const outOfStock = isOutOfStock({
    outOfStock: menuItem?.outOfStock,
    outOfStockUntil: menuItem?.outOfStockUntil,
    scheduledAt,
  });
  const soldOut = isSoldOut(menuItem.orderingEventMaxAvailable);
  const invalidCateringLeadTime = menuItem.section.menu.isCateringMenu &&
    menuItem.section.menu.cateringLeadTimeMinutes > 0 &&
    isScheduled &&
    isBefore(parseISO(scheduledAt), addMinutes(new Date(), menuItem.section.menu.cateringLeadTimeMinutes));
  const showOrderLink = (
    !menuEmbed &&
    theme.isOrderingButtonEnabled
  );

  const closeMenuItemAndOpenModalInCart = () => {
    if (closeMenuItemModal) {
      closeMenuItemModal();
    }
    openModalInCart();
  };

  return (
    <div
      className={classNames(
        classes.infoContainer,
        closeMenuItemModal ? classes.infoContainerModal : null,
        menuItem.photos.length === 0 ? classes.infoContainerWithoutPhotos : null,
      )}
      id="pm-item-scroll-container"
    >
      {theme.useLegacyDishLayout ? (
        <LegacyMenuItemDetailsInfo
          closeMenuItemModal={closeMenuItemModal}
          menuItem={menuItem}
        />
      ) : (
        <section>
          <div className={classes.infoTop}>
            <MenuItemCloseButton
              className={classNames(
                classes.closeButton,
                menuItem.photos.length > 0 ? classes.closeButtonWithPhotos : null,
              )}
              closeMenuItemModal={closeMenuItemModal}
              menuItem={menuItem}
            />
            <div
              className={classNames(
                classes.shareButton,
                menuItem.photos.length === 0 ? classes.shareButtonWithoutPhotos : null,
              )}
            >
              <ShareButton
                eventableId={menuItem.id}
                eventableType="MenuItem"
                photoUrl={menuItem.photos.length > 0 ? menuItem.photos[0].photoUrl : null}
                quote={`Check out ${menuItem.name}: ${restaurant.popmenuUrl}${menuItem.url}`}
                url={`${restaurant.popmenuUrl}${menuItem.url}`}
              >
                <FormattedMessage id="forms.share" defaultMessage="Share" />
              </ShareButton>
            </div>
            <div className={classes.popBubble}>
              <PopBubble
                likedItPopsCount={menuItem.likedItPopsCount}
                lovedItPopsCount={menuItem.lovedItPopsCount}
                menuItemId={menuItem.id}
                menuItemName={menuItem.name}
                poppableId={menuItem.dishId}
                poppableType="Dish"
                popsCount={menuItem.popsCount}
                wannaTryPopsCount={menuItem.wannaTryPopsCount}
              />
            </div>
          </div>
          <Typography className={classNames(classes.menuName, 'pm-menu-item-details-menu-name')}>
            <FormattedMessage
              id="menu_item_details_info.section_and_menu_name"
              defaultMessage="{sectionName} - {menuName}"
              values={{
                menuName: menuItem.section.menu.name,
                sectionName: menuItem.section.name,
              }}
            />
            <br />
            {restaurant.isSingleLocation ? null : `${menuItem.section.menu.location.name}`}
          </Typography>
          <AH typography className={classNames(classes.dishName, 'pm-menu-item-details-name')} variant="h1">
            {menuItem.name}
          </AH>
          <AHLevelProvider>
            {theme.showPrice && Boolean(menuItem.priceCustomText || menuItem.price) && (
              <Typography className={classNames(classes.dishPrice, 'pm-menu-item-details-price')}>
                {menuItem.priceCustomText || formatCurrency(menuItem.price, menuItem.currency, { showSymbol: theme.showCurrencySymbol })}
              </Typography>
            )}
            {menuItem.description && (
              <Typography className={classNames(classes.dishDescription, 'pm-menu-item-details-description')}>
                {nl2br(menuItem.description)}
              </Typography>
            )}
            {(menuItem.htmlContent && restaurant.featureSetting.isDishCustomHtmlEnabled) && (
              <React.Fragment>
                <br />
                <CustomContent id="custom_dish_html_content" html={menuItem.htmlContent} />
              </React.Fragment>
            )}
            {theme.showPrice && (
              <DishExtras
                classes={classes}
                currency={menuItem.currency}
                displayExtraGroups={theme.showMenuItemExtraGroups}
                extraGroups={menuItem.extraGroups}
                extras={menuItem.extras}
                sharedExtraGroups={menuItem.sharedExtraGroups}
                showCurrencySymbol={theme.showCurrencySymbol}
                sizes={menuItem.sizes}
              />
            )}
            <DishTags
              allSelectedDishTags={menuItem.dish.allSelectedDishTags}
              classes={classes}
            />
            {!showAddToCartButton && showOrderLink && (
              // The order online link will display when this component is rendered
              // in a standard menu section or menu modal, or a menu item details page
              // and theme.isOrderingButtonEnabled is true
              <Button
                className={classNames(classes.addToCartButton, 'pm-add-to-cart-button')}
                data-cy="order_online"
                component={(outOfStock || isNewOrdering) ? undefined : Link}
                role={(outOfStock || isNewOrdering) ? undefined : 'link'}
                to={(outOfStock || isNewOrdering) ? undefined : (menuItem.section.menu.menuOrderingUrl || menuOrderingUrl)}
                disabled={(!menuItem.section.menu.menuOrderingUrl && !menuOrderingUrl) ||
                  !menuItem.isOrderingEnabled ||
                  outOfStock}
                onClick={() => {
                  if (isNewOrdering) {
                    const locationParam = `location=${menuItem.section.menu.location.name.toLowerCase().replace(/ /g, '-')}`;
                    const menuParam = `menu=${menuItem.section.menu.name.toLowerCase().replace(/ /g, '-')}`;
                    const itemParam = `item=${menuItem.name.toLowerCase().replace(/ /g, '-')}`;
                    const orderingPath = props.restaurant.onlineOrderDefaultUrl;
                    const newOrderingUrl = `${orderingPath}/?${locationParam}&${menuParam}&${itemParam}`;
                    window.location.href = newOrderingUrl;
                  } else {
                    createEvent({
                      eventableType: 'OnlineOrdering',
                      eventType: 'order_online_button_on_dish',
                    });
                    dispatch(setSelectedMenuItem({
                      menuId: menuItem.menu?.id,
                      sectionId: menuItem.section?.id,
                    }));
                    closeMenuItemAndOpenModalInCart();
                  }
                }}
                size="large"
                variant="outlined"
              >
                {outOfStock ? <FormattedMessage id="menu_item_details_info.out_of_stock_1" defaultMessage="Out Of Stock" /> : <FormattedMessage id="menu_item_details_info.order_online" defaultMessage="Order Online" />}
              </Button>
            )}
            {showAddToCartButton && !menuEmbed && (
              // The add to cart link will display when this component is rendered in
              // the online ordering page menu.
              <Button
                aria-label={`Add ${menuItem.name} to order`}
                className={classNames(classes.addToCartButton, 'pm-add-to-cart-button')}
                disabled={!menuItem.isOrderingEnabled || outOfStock || !menuEnabled || invalidCateringLeadTime || soldOut}
                startIcon={<Icon icon={CartPlus} />}
                onClick={() => {
                  executeWithProgressBar(() => {
                    createEvent({
                      eventableType: 'OnlineOrdering',
                      eventType: 'add_to_order_dish_button',
                    });
                    openModalInCart();
                  });
                }}
                size="large"
                style={{
                  backgroundColor: (outOfStock || soldOut) ? '#FFF' : null,
                  borderColor: (outOfStock || soldOut) ? '#000' : null,
                  color: (outOfStock || soldOut) ? 'rgba(100, 100, 100, 0.35)' : null,
                }}
                variant="outlined"
                data-cy="add_to_order_details"
                data-vwo-tracker="add-to-cart"
              >
                { soldOut ? <FormattedMessage id="menu_item_details_info.sold_out" defaultMessage="Sold Out" /> :
                  outOfStock ? <FormattedMessage id="menu_item_details_info.out_of_stock_2" defaultMessage="Out Of Stock" /> :
                  <FormattedMessage id="menu_item_details_info.add_to_order" defaultMessage="Add to Order" />}
              </Button>
            )}
            <aside>
              <AH typography className={classNames(classes.reviewHeading, 'pm-menu-item-details-review-heading')} variant="h3">
                <FormattedMessage id="menu_item_details_info.tried_this_item" defaultMessage="Have you tried this item?" />
              </AH>
              <Typography className={classNames(classes.reviewContent, 'pm-menu-item-details-review-content')}>
                <FormattedMessage id="menu_item_details_info.add_your_review" defaultMessage="Add your review below to help others know what to expect." />
              </Typography>
            </aside>
          </AHLevelProvider>
        </section>
      )}
      <AHLevelProvider>
        {theme.useLegacyDishLayout && (
          <PopList
            menuItem={menuItem}
          />
        )}
        <MenuItemDetailsTabs
          isLocationCustomTabDefault={isLocationCustomTabDefault}
          includeItemLink={!closeMenuItemModal}
          menuItem={menuItem}
          openReviewModal={openReviewModal}
        />
      </AHLevelProvider>
    </div>
  );
};

MenuItemDetailsInfo.defaultProps = {
  closeMenuItemModal: null,
  isScheduled: false,
  menuEnabled: true,
  scheduledAt: null,
};

MenuItemDetailsInfo.propTypes = {
  closeMenuItemModal: PropTypes.func,
  isScheduled: PropTypes.bool,
  menuEnabled: PropTypes.bool,
  menuItem: PropTypes.shape({
    dishId: PropTypes.number,
    id: PropTypes.number,
    name: PropTypes.string,
    reviews: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
    })),
  }).isRequired,
  openModalInCart: PropTypes.func.isRequired,
  openReviewModal: PropTypes.func.isRequired,
  scheduledAt: PropTypes.string,
  showAddToCartButton: PropTypes.bool.isRequired,
  theme: PropTypes.shape({
    showCurrencySymbol: PropTypes.bool,
    showPrice: PropTypes.bool,
    useLegacyDishLayout: PropTypes.bool,
  }).isRequired,
};

export default compose(
  withRestaurant,
  withTheme,
)(MenuItemDetailsInfo);
